import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import commonAPIService from "../../common/js/common-api-service";
export default {
  data: () => ({
    name: "WorkCenter",
    mainPage: "",
    subPage: "",
    pageUrl: "",
    workcenter: true,
    backSlash: true,
    selectedProject: "",
    selectedBusiness: "",
    favouriteList: [],
    showAdd: false,
    sourceData: "",
    projectData: [],
    businessData: [],
    sourceList: [],
    userId: EncryptUtility.localStorageDecrypt("userID"),
    projectSelected: true,
    businessSelected: true,
    popupTitleWorkCenter: "",
    workCenterUpdateDialog: false,
    headers: [
      {
        text: "Active",
        align: "start",
        value: "Active",
        class: "primary customwhite--text",
      },
      {
        text: "Destination Workcenter",
        value: "WcDescription",
        class: "primary customwhite--text",
      },
    ],
    refresh: true,
    selectedBusinessData: [],
    selectedProjectData: [],
    selectedSourceData: [],
    DestinationList: [],
    totalRecords: 0,
    workcenterShow: false,
    changeStatusParent: [],
  }),
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  mounted() {
    this.getProjectList();
  },

  methods: {
    //Get Page Action and name
    //On Click of Favorite icon
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //To set the action
    actionSetter(action) {
      if (this.actionList !== null && this.actionList.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    //Get Projects List
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProject("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    //Project Selected
    onProjectSelect() {
      this.selectedProjectData = [];
      this.selectedProjectData = this.projectData.filter((obj) => obj.ProjKey === this.selectedProject);
      this.getBusinessList(this.selectedProject);
      this.selectedSourceWorkcenter = "";
      this.projectSelected = false;
      this.businessSelected = true;
      this.workcenterShow = false;
    },
    //Get Business List
    getBusinessList(selProject) {
      this.businessData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/business_header?Pass=2&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.businessData = responseData.filter((obj) => obj.ProjKey === selProject);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Business Selected
    onBusinessSelect() {
      this.selectedBusinessData = [];
      this.selectedBusinessData = this.businessData.filter((obj) => obj.WcLoc === this.selectedBusiness);
      this.getSourceDetails();
      this.businessSelected = false;
      this.workcenterShow = false;
    },
    //Get Source List
    getSourceDetails() {
      this.sourceList = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/wc_matrix_sourcedetails?Buid=${this.selectedBusiness}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.sourceList = responseData.WorkCenterDetail;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Source work center selected
    onSourceWorkCenterSelect() {
      this.selectedSourceData = [];
      this.selectedSourceData = this.sourceList.filter((obj) => obj.code === this.sourceList);
      this.getDestinationList();
      this.workcenterShow = true;
    },
    //Getting the Destination List
    getDestinationList() {
      this.DestinationList = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.totalRecords = 0;
      this.axios
        .get(`/cp/wc_matrix_destinationdetails?Loc=${this.selectedSourceWorkcenter}&Buid=${this.selectedBusiness}`)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.DestinationList = responseData.DestinationWorkcenterDetail;
            this.workcenterShow = true;
            this.totalRecords = responseData.DestinationWorkcenterDetail.length;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Changing the status of the Work Center
    changeActiveWorkCenter(item) {
      if (item.Active == true) {
        this.popupTitleWorkCenter = "Are you sure you want to disable this item?";
      } else if (item.Active == false) {
        this.popupTitleWorkCenter = "Are you sure you want to enable this item?";
      }
      this.workCenterUpdateDialog = true;
      this.changeStatusParent = item;
    },
    //Ok button click on status change popup for Work Center to Update
    workCenterUpdate() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let Movements = [];
      Movements.push({
        Loc: this.changeStatusParent.WcLoc,
        LocAdjTo: this.changeStatusParent.WcLocAdjto,
        BuKey: parseInt(this.selectedBusiness),
        Active: !this.changeStatusParent.Active,
      });

      let addObject = {
        InputJson: JSON.stringify({
          Movements: Movements,
        }),
      };
      this.axios
        .post("/cp/wc_matrix_update", addObject)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.getDestinationList();
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.workCenterUpdateDialog = false;
            this.getDestinationListNoLoader()
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.workCenterUpdateDialog = false;
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
          this.workCenterUpdateDialog = false;
        });
    },
    //Cancel button on status change popup
    onClickCancel() {
      this.workCenterUpdateDialog = false;
  //    this.getDestinationListNoLoader();
    },
    //Reset Function
    resetFunction() {
      this.getProjectList();
      this.selectedProject = "";
      this.selectedBusiness = "";
      this.selectedSourceWorkcenter = "";
      this.sourceData = "";
      this.DestinationList = [];
      this.sourceList = [];
      this.businessData = [];
      this.projectData = [];
      this.projectSelected = true;
      this.businessSelected = true;
      this.workCenterUpdateDialog = false;
      this.workcenterShow = false;
    },
    //Getting the Destination List without loader
    getDestinationListNoLoader() {
      this.axios
        .get(`/cp/wc_matrix_destinationdetails?Loc=${this.selectedSourceWorkcenter}&Buid=${this.selectedBusiness}`)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            this.DestinationList = responseData.DestinationWorkcenterDetail;
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
  },
  components: {
    breadcrumbComp,
  },
};
